@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selected-left {
  color: #282c34;
  width: 50px;
  position: relative;
}

.selected-left:hover {
  background-color: #108236;
  height: 50px;
  border-radius: 8px;
  padding: 5px;
  width: 50px;
}

/* .selected-left::after {
  position: absolute;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #108236;
  top: 45%;
  right: 14px;
} */

.selected-left select {
  font-size: 14px;
  font-weight: 800;
  height: 39px;
  background: none;
}

.selected-right {
  color: #282c34;
  width: 50px;
  position: relative;
}

.selected-right:hover {
  background-color: #108236;
  height: 50px;
  border-radius: 8px;
  padding: 5px;
  width: 50px;
}

/* .selected-right::after {
  position: absolute;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #108236;
  top: 45%;
  left: 14px;
} */

.selected-right select {
  font-size: 14px;
  font-weight: 800;
  height: 39px;
  background: none;
  overflow: hidden;
}

@layer components {
  .heading {
    @apply text-center text-[#108236] text-[28px] sm:text-[33px] md:text-[45px] font-bold uppercase;
  }
}